/* Footer  ##################################################################################################################### */

.footer-section {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #000000;
}
.footer-section .social-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.footer-section .social-row .soc-item {
  margin: 8px;
}
.footer-section .social-row .soc-item a:hover .mbr-iconfont,
.footer-section .social-row .soc-item a:focus .mbr-iconfont {
  background-color: #ffffff;
}
.footer-section .social-row .soc-item a .mbr-iconfont {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 32px;
  background-color: #edefeb;
  color: #000000;
  transition: all 0.3s ease-in-out;
}
.footer-section .row-links {
  width: 100%;
  justify-content: center;
}
.footer-section .header-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
}
.footer-section .header-menu li {
  padding: 0 1rem 1rem 1rem;
}
.footer-section .header-menu li p {
  margin: 0;
}
.footer-section .copyright {
  margin-bottom: 0;
  color: #ffffff;
  text-align: center;
}
.footer-section .mbr-section-title {
  color: #ffffff;
}

.footer-section .copyright img {
  height: 22px!important;
  margin-left: 3px;
  vertical-align: text-bottom;
  width: auto;
}

.footer-section .copyright a {
  display: inline-flex;
}