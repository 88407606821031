#mc-result span {
    word-break: normal !important;
}

.offcanvas-header {
    background-color: var(--primary-color);
    font-size: 1.2em;
    font-weight: bold;
    color: white;
}

.offcanvas-body div  {
    display: flex;
    margin: 4px;
    padding: 1px 6px;
    background-color: #e4e8eb;
}

.offcanvas-body div:hover  {
    background-color: var(--lightest-grey-color);
    color: white;
}

.offcanvas-body div label {
    display: block;
    width: 100%;
    padding-left: 10px;
}