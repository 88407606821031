.header-section  {
  z-index: 1000;
  width: 100%;
  position: relative;
}
.header-section  .dropdown-item:before {
  font-family: Moririse2 !important;
  content: "\e966";
  display: inline-block;
  width: 0;
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1;
  font-size: inherit;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  transform: scale(0, 1);
  transition: all 0.25s ease-in-out;
}
@media (max-width: 767px) {
  .header-section  .navbar-toggler {
    transform: scale(0.8);
  }
}
.header-section  .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
  padding: 10px 0;
  transition: all 0.3s;
  word-break: break-word;
  z-index: 1;
}
.header-section  .navbar-brand img {
  max-width: 100%;
  max-height: 100%;
}
.header-section  .navbar-brand .navbar-caption {
  line-height: inherit !important;
}
.header-section  .navbar-brand .navbar-logo a {
  outline: none;
}
.header-section  .navbar-nav {
  margin: auto;
  margin-left: 0;
  margin-left: auto;
}
.header-section  .navbar-nav .nav-item {
  padding: 0 !important;
  transition: 0.3s all !important;
}
.header-section  .navbar-nav .nav-item .nav-link {
  padding: 16px !important;
  margin: 0 !important;
  border-radius: 1rem !important;
  transition: 0.3s all !important;
}
.header-section  .navbar-nav .nav-item .nav-link:hover {
  background-color: rgba(27, 31, 10, 0.06);
}
.header-section  .navbar-nav .open .nav-link::after {
  transform: rotate(180deg);
}
@media (min-width: 992px) {
  .header-section  .navbar-nav .open .nav-link::before {
    content: "";
    width: 100%;
    height: 20px;
    top: 100%;
    background: transparent;
    position: absolute;
  }
}
.header-section  .navbar-nav .dropdown-item {
  padding: 12px !important;
  border-radius: 0.5rem !important;
  margin: 0 8px !important;
  transition: 0.3s all !important;
}
.header-section  .navbar-nav .dropdown-item:hover {
  background-color: rgba(27, 31, 10, 0.06);
}
@media (min-width: 992px) {
  .header-section  .navbar-nav {
    padding-left: 1.5rem;
  }
}
.header-section  .nav-link {
  width: fit-content;
  position: relative;
}
.header-section  .navbar-logo {
  margin: 0 !important;
}
@media (max-width: 767px) {
  .header-section  .navbar-logo {
    padding-left: 0;
  }
}
.header-section  .navbar-caption {
  padding-left: 1rem;
  padding-right: 0.5rem;
}
@media (max-width: 767px) {
  .header-section  .nav-dropdown {
    padding-bottom: 0.5rem;
  }
}
.header-section  .nav-dropdown .link.dropdown-toggle::after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  transition: 0.3s all;
}
.header-section  .container {
  display: flex;
  height: 90px;
  padding: 0.5rem 0.6rem;
  flex-wrap: nowrap;
  background: rgba(255, 255, 255, 0.8) !important;
  left: 0;
  right: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100vw;
  margin-top: 1rem;
  background-color: #ffffff;
  box-shadow: 0 30px 60px 0 rgba(27, 31, 10, 0.08);
}
@media (max-width: 992px) {
  .header-section  .container {
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .header-section  .container {
    width: 95%;
    height: 56px !important;
    padding-right: 1rem;
    margin-top: 0rem;
  }
}
.header-section  .iconfont-wrapper {
  color: #000000 !important;
  font-size: 1.5rem;
  padding-right: 0.5rem;
}
.header-section  .dropdown-menu {
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 100%;
  padding: 12px 4px !important;
  border-radius: 1.5rem;
  transition: 0.3s all !important;
  min-width: auto;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.8) !important;
}
.header-section  .nav-item:focus,
.header-section  .nav-link:focus {
  outline: none;
}
.header-section  .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  transition: all 0.25s ease-in-out;
}
.header-section  .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.header-section  .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-right: 0.5rem;
  vertical-align: sub;
}
.header-section  .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.header-section  .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.header-section  .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}
.header-section  .navbar {
  min-height: 90px;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.header-section  .navbar.opened {
  transition: all 0.3s;
}
.header-section  .navbar .dropdown-item {
  padding: 0.5rem 1.8rem;
}
.header-section  .navbar .navbar-logo img {
  min-width: 6rem;
  object-fit: cover;
}
.header-section  .navbar .navbar-collapse {
  z-index: 1;
  justify-content: flex-end;
}
.header-section  .navbar.collapsed {
  justify-content: center;
}
.header-section  .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.header-section  .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .header-section  .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(98.5vh - 3rem);
  }
}
.header-section  .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.header-section  .navbar.collapsed .dropdown-menu .dropdown-item:after {
  right: auto;
}
.header-section  .navbar.collapsed .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
  border-bottom: 0;
  top: 41%;
}
.header-section  .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}
.header-section  .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
}
.header-section  .navbar.collapsed .icons-menu {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (max-width: 767px) {
  .header-section  .navbar {
    min-height: 72px;
  }
  .header-section  .navbar .navbar-logo img {
    height: 2.5rem !important;
    min-width: 2.5rem !important;
  }
}
@media (max-width: 991px) {
  .header-section  .navbar .nav-item .nav-link::before {
    display: none;
  }
  .header-section  .navbar.opened .dropdown-menu {
    top: 0;
  }
  .header-section  .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }
  .header-section  .navbar .dropdown-menu .dropdown-item:after {
    right: auto;
  }
  .header-section  .navbar .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0;
    top: 40%;
  }
  .header-section  .navbar .dropdown-menu .dropdown-item {
    padding: 0.25rem 1.5rem !important;
    text-align: center;
  }
  .header-section  .navbar .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    padding-right: 10px;
  }
  .header-section  .navbar .navbar-toggler {
    flex-basis: auto;
  }
  .header-section  .navbar .icons-menu {
    padding-left: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.header-section  .navbar.navbar-short .navbar-logo img {
  height: 2rem;
}
.header-section  .dropdown-item.active,
.header-section  .dropdown-item:active {
  background-color: transparent;
}
.header-section  .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
}
.header-section  .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}
.header-section  .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}
.header-section  .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #ffffff;
}
.header-section  .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
  left: 105%;
  transform: none;
  top: -12px;
}
.header-section  .navbar .dropdown.open > .dropdown-menu {
  display: flex;
}
.header-section  ul.navbar-nav {
  flex-wrap: wrap;
}
.header-section  .navbar-buttons {
  text-align: center;
  min-width: 140px;
}
@media (max-width: 992px) {
  .header-section  .navbar-buttons {
    text-align: left;
  }
}
.header-section  button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.header-section  button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #000000;
}
.header-section  button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.header-section  button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.header-section  button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.header-section  button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.header-section  nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.header-section  nav.opened .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  transition: all 0.25s;
}
.header-section  nav.opened .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.header-section  nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.header-section  .navbar-dropdown {
  padding: 0 1rem;
}
.header-section  a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-section  .icons-menu {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  text-align: center;
}
@media (max-width: 992px) {
  .header-section  .icons-menu {
    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header-section  .navbar {
    height: 70px;
  }
  .header-section  .navbar.opened {
    height: auto;
  }
  .header-section  .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.header-section  .navbar .dropdown > .dropdown-menu {
  display: none;
  width: max-content;
  max-width: 500px !important;
  transform: translateX(-50%);
  top: calc(100% + 20px);
  left: 50%;
}
.header-section  .navbar .dropdown > .dropdown-menu .dropdown-item {
  line-height: 1 !important;
}
.header-section  .navbar .dropdown > .dropdown-menu .dropdown .dropdown-item {
  align-items: center;
  display: flex;
  height: max-content !important;
  min-height: max-content !important;
}
.header-section  .navbar .dropdown > .dropdown-menu .dropdown .dropdown-item::after {
  display: inline-block;
  position: static;
  margin-left: 0.5rem;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  transition: 0.3s all;
  transform: rotate(-90deg);
}
.header-section  .navbar .dropdown > .dropdown-menu .dropdown.open .dropdown-item::after {
  transform: rotate(0deg);
}
.header-section  .mbr-section-btn {
  margin: -0.6rem -0.6rem;
}
.header-section  .navbar-toggler {
  margin-left: 12px;
  margin-right: 8px;
  order: 1000;
}
@media (max-width: 991px) {
  .header-section  .navbar-brand {
    margin-right: auto;
  }
  .header-section  .navbar-collapse {
    z-index: -1 !important;
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    padding: 1rem;
    border-radius: 1.5rem;
    background: #ffffff;
    opacity: 1;
    border-color: rgba(255, 255, 255, 0.8) !important;
    background: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(8px);
  }
  .header-section  .navbar-nav .nav-item .nav-link::after {
    margin-left: 10px;
  }
  .header-section  .navbar-nav .dropdown-item:hover {
    background-color: rgba(27, 31, 10, 0.06);
  }
  .header-section  .navbar .dropdown > .dropdown-menu {
    max-width: 100% !important;
    transform: translateX(0);
    top: 10px;
    left: 0;
    padding: 8px !important;
    border-radius: 1rem;
    background-color: rgba(27, 31, 10, 0.04) !important;
  }
  .header-section  .navbar .dropdown > .dropdown-menu .dropdown-item {
    padding: 8px !important;
    line-height: 1 !important;
    margin-bottom: 4px !important;
  }
  .header-section  .navbar .dropdown > .dropdown-menu .dropdown .dropdown-item {
    align-items: center;
    display: flex;
    height: max-content !important;
    min-height: max-content !important;
  }
  .header-section  .navbar .dropdown > .dropdown-menu .dropdown .dropdown-item::after {
    display: inline-block;
    position: static;
    margin-left: 0.5rem;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    transition: 0.3s all;
    transform: rotate(0deg);
  }
  .header-section  .navbar .dropdown > .dropdown-menu .dropdown.open .dropdown-item::after {
    transform: rotate(180deg);
  }
  .header-section  .navbar .dropdown > .dropdown-menu .dropdown-submenu {
    position: static;
    width: 100%;
    max-width: 100% !important;
    transform: translateX(0) !important;
    top: 0;
    left: 0;
    padding: 8px !important;
    border-radius: 1rem;
    background-color: rgba(27, 31, 10, 0.04) !important;
  }
  .header-section  .navbar .dropdown.open > .dropdown-menu {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 575px) {
  .header-section  .navbar-collapse {
    padding: 1rem;
  }
}