/* MoultDB Project ##################################################################################################################### */


  .project-presentation-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}
.project-presentation-section .mbr-fallback-image.disabled {
  display: none;
}
.project-presentation-section .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (max-width: 991px) {
  .project-presentation-section .image-wrapper {
    margin-bottom: 2rem;
  }
}
.project-presentation-section .row {
  flex-direction: row-reverse;
}
.project-presentation-section .row {
  align-items: center;
}
@media (max-width: 991px) {
  .project-presentation-section .image-wrapper {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .project-presentation-section .text-wrapper {
    padding: 0 2rem;
  }
}
.project-presentation-section .mbr-section-title {
  color: #000000;
}
.project-presentation-section .mbr-text,
.project-presentation-section .mbr-section-btn {
  color: #000000;
}