:root {
  --main-dark-color:#08786E;
  --main-medium-color:#138379;
  --main-light-color:#FFE8D3;
  --lightest-grey-color: #767676; /* lightest gray acceptable so that the contrast with the white background is sufficient for color blind */
  --background: #FEF5ED;
  --dominant-color: #F49E4C;
  --primary-color: #2D728F;
  --secondary-color: #3B8EA5;
  --success-color: #20AC6B;
  --danger-color: #AE1E2C;
  --warning-color: #CC9900;
  --info-color: #0AA3C2;
  --background-text: #000000;
  --dominant-text: #000000;
  --primary-text: #FFFFFF;
  --secondary-text: #FFFFFF;
  --success-text: #FFFFFF;
  --danger-text: #FFFFFF;
  --warning-text: #000000;
  --info-text: #FFFFFF;
}


.btn {
  border-width: 2px;
}
img,
.card-wrap,
.card-wrapper,
.video-wrapper,
.mbr-figure iframe,
.google-map iframe,
.slide-content,
.plan,
.card,
.item-wrapper {
  border-radius: 2rem !important;
}
.video-wrapper {
  overflow: hidden;
}
body {
  font-family: var(--display1-font, 'Inter Tight');
  background: color-mix(in srgb, var(--dominant-color, #0f1305) 10%, transparent);

}

.display-1 {
  font-family: var(--display1-font);
  font-size: calc(var(--display1-size, 5) * 1rem);
  line-height: 1;
}
.display-2 {
  font-family: var(--display2-font);
  font-size: calc(var(--display2-size, 4) * 1rem);
  line-height: 1.1;
}
.display-4 {
  font-family: var(--display4-font);
  font-size: calc(var(--display4-size, 1.4) * 1rem);
  line-height: 1.5;
}
.display-5 {
  font-family: var(--display5-font);
  font-size: calc(var(--display5-size, 2) * 1rem);
  line-height: 1.5;
}
.display-7 {
  font-family: var(--display7-font);
  font-size: calc(var(--display7-size, 1.4) * 1rem);
  line-height: 1.5;
}

.display-1 > .mbr-iconfont {
  font-size: calc(var(--display1-size, 5) * 1.25rem);
}
.display-2 > .mbr-iconfont {
  font-size: calc(var(--display2-size, 4) * 1.25rem);
}
.display-4 > .mbr-iconfont {
  font-size: calc(var(--display4-size, 1.4) * 1.25rem);
}
.display-5 > .mbr-iconfont {
  font-size: calc(var(--display5-size, 2) * 1.25rem);
}
.display-7 > .mbr-iconfont {
  font-size: calc(var(--display7-size, 1.4) * 1.25rem);
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 992px) {
  .display-1 {
    font-size: calc(var(--display1-size, 5) * 0.8rem);
  }
}
@media (max-width: 768px) {
  .display-1 {
    --display1-size_min: calc(var(--display1-size, 5) - (var(--display1-size, 5) - 1) * 0.65);
    font-size: calc(var(--display1-size, 5) * 0.7rem);
    font-size: calc(var(--display1-size_min) * 1rem + (var(--display1-size, 5) - var(--display1-size_min)) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.1 * (var(--display1-size_min) * 1rem + (var(--display1-size, 5) - var(--display1-size_min)) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-2 {
    --display2-size_min: calc(var(--display2-size, 4) - (var(--display2-size, 4) - 1) * 0.65);
    font-size: calc(var(--display2-size, 4) * 0.8rem);
    font-size: calc(var(--display2-size_min) * 1rem + (var(--display2-size, 4) - var(--display2-size_min)) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.3 * (var(--display2-size_min) * 1rem + (var(--display2-size, 4) - var(--display2-size_min)) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-4 {
    --display4-size_min: calc(var(--display4-size, 1.4) - (var(--display4-size, 1.4) - 1) * 0.65);
    font-size: calc(var(--display4-size, 1.4) * 0.8rem);
    font-size: calc(var(--display4-size_min) * 1rem + (var(--display4-size, 1.4) - var(--display4-size_min)) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.4 * (var(--display4-size_min) * 1rem + (var(--display4-size, 1.4) - var(--display4-size_min)) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-5 {
    --display5-size_min: calc(var(--display5-size, 2) - (var(--display5-size, 2) - 1) * 0.65);
    font-size: calc(var(--display5-size, 2) * 0.8rem);
    font-size: calc(var(--display5-size_min) * 1rem + (var(--display5-size, 2) - var(--display5-size_min)) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.4 * (var(--display5-size_min) * 1rem + (var(--display5-size, 2) - var(--display5-size_min)) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-7 {
    --display7-size_min: calc(var(--display7-size, 1.4) - (var(--display7-size, 1.4) - 1) * 0.65);
    font-size: calc(var(--display7-size, 1.4) * 0.8rem);
    font-size: calc(var(--display7-size_min) * 1rem + (var(--display7-size, 1.4) - var(--display7-size_min)) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.4 * (var(--display7-size_min) * 1rem + (var(--display7-size, 1.4) - var(--display7-size_min)) * ((100vw - 20rem) / (48 - 20))));
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .display-1 {
      --display1-size_min: calc(var(--display1-size, 5) - (var(--display1-size, 5) - 1) * 0.65);
      font-size: calc(var(--display1-size, 5) * 0.7rem);
      font-size: calc(var(--display1-size_min) * 1rem + (var(--display1-size, 5) - var(--display1-size_min)) * ((100vw - 62rem) / (87 - 62)));
      line-height: calc( 1.1 * (var(--display1-size_min) * 1rem + (var(--display1-size, 5) - var(--display1-size_min)) * ((100vw - 62rem) / (87 - 62))));
  }

  .display-2 {
      --display2-size_min: calc(var(--display2-size, 4) - (var(--display2-size, 4) - 1) * 0.65);
      font-size: calc(var(--display2-size, 4) * 0.8rem);
      font-size: calc(var(--display2-size_min) * 1rem + (var(--display2-size, 4) - var(--display2-size_min)) * ((100vw - 62rem) / (87 - 62)));
      line-height: calc(1.3 * (var(--display2-size_min) * 1rem + (var(--display2-size, 4) - var(--display2-size_min)) * ((100vw - 62rem) / (87 - 62))));
  }

  .display-4 {
      --display4-size_min: calc(var(--display4-size, 1.4) - (var(--display4-size, 1.4) - 1) * 0.65);
      font-size: calc(var(--display4-size, 1.4) * 0.8rem);
      font-size: calc(var(--display4-size_min) * 1rem + (var(--display4-size, 1.4) - var(--display4-size_min)) * ((100vw - 62rem) / (87 - 62)));
      line-height: calc(1.4 * (var(--display4-size_min) * 1rem + (var(--display4-size, 1.4) - var(--display4-size_min)) * ((100vw - 62rem) / (87 - 62))));
  }

  .display-5 {
      --display5-size_min: calc(var(--display5-size, 2) - (var(--display5-size, 2) - 1) * 0.65);
      font-size: calc(var(--display5-size, 2) * 0.8rem);
      font-size: calc(var(--display5-size_min) * 1rem + (var(--display5-size, 2) - var(--display5-size_min)) * ((100vw - 62rem) / (87 - 62)));
      line-height: calc(1.4 * (var(--display5-size_min) * 1rem + (var(--display5-size, 2) - var(--display5-size_min)) * ((100vw - 62rem) / (87 - 62))));
  }

  .display-7 {
      --display7-size_min: calc(var(--display7-size, 1.4) - (var(--display7-size, 1.4) - 1) * 0.65);
      font-size: calc(var(--display7-size, 1.4) * 0.8rem);
      font-size: calc(var(--display7-size_min) * 1rem + (var(--display7-size, 1.4) - var(--display7-size_min)) * ((100vw - 62rem) / (87 - 62)));
      line-height: calc(1.4 * (var(--display7-size_min) * 1rem + (var(--display7-size, 1.4) - var(--display7-size_min)) * ((100vw - 62rem) / (87 - 62))));
  }
}
/* Buttons */
.btn {
  padding: 1.25rem 2rem;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .btn {
    padding: 0.75rem 1.5rem;
  }
}
.btn-sm {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-md {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-lg {
  padding: 1.25rem 2rem;
  border-radius: 4px;
}
.bg-primary {
  background-color: var(--primary-color, #9fe870) !important;
}
.btn-secondary {
  background-color: var(--secondary-color, #ffd7ef) !important;
}
.bg-success {
  background-color: var(--success-color, #3a341c) !important;
}
.bg-info {
  background-color: var(--info-color, #320707) !important;
}
.bg-warning {
  background-color: var(--warning-color, #a0e2e1) !important;
}
.bg-danger {
  background-color: var(--danger-color, #ffea64) !important;
}
.btn-primary,
.btn-primary:active {
  background-color: var(--primary-color, #9fe870) !important;
  border-color: var(--primary-color, #9fe870) !important;
  color: var(--primary-text, #264d0c) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: inherit;
  background-color: color-mix(in srgb,var(--primary-color, #9fe870),#000 20%) !important;
  border-color: color-mix(in srgb,var(--primary-color, #9fe870),#000 20%) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--primary-text, #264d0c) !important;
  background-color: color-mix(in srgb,var(--primary-color, #9fe870),#000 40%) !important;
  border-color: color-mix(in srgb,var(--primary-color, #9fe870),#000 40%) !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: var(--secondary-color, #ffd7ef) !important;
  border-color: var(--secondary-color, #ffd7ef) !important;
  color: var(--secondary-text, #d70081) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: inherit;
  background-color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 20%) !important;
  border-color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 20%) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: var(--secondary-text, #d70081) !important;
  background-color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 40%) !important;
  border-color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 40%) !important;
}
.btn-info,
.btn-info:active {
  background-color: var(--info-color, #320707) !important;
  border-color: var(--info-color, #320707) !important;
  color: var(--info-text, #ffffff) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: inherit;
  background-color: color-mix(in srgb,var(--info-color, #320707),#000 20%) !important;
  border-color: color-mix(in srgb,var(--info-color, #320707),#000 20%) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-info.disabled,
.btn-info:disabled {
  color: var(--info-text, #ffffff) !important;
  background-color: color-mix(in srgb,var(--info-color, #320707),#000 40%) !important;
  border-color: color-mix(in srgb,var(--info-color, #320707),#000 40%) !important;
}
.btn-success,
.btn-success:active {
  background-color: var(--success-color, #3a341c) !important;
  border-color: var(--success-color, #3a341c) !important;
  color: var(--success-text, #ffffff) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: inherit;
  background-color: color-mix(in srgb,var(--success-color, #3a341c),#000 20%) !important;
  border-color: color-mix(in srgb,var(--success-color, #3a341c),#000 20%) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-success.disabled,
.btn-success:disabled {
  color: var(--success-text, #ffffff) !important;
  background-color: color-mix(in srgb,var(--success-color, #3a341c),#000 40%) !important;
  border-color: color-mix(in srgb,var(--success-color, #3a341c),#000 40%) !important;
}
.btn-warning,
.btn-warning:active {
  background-color: var(--warning-color, #a0e2e1) !important;
  border-color: var(--warning-color, #a0e2e1) !important;
  color: var(--warning-text, #1f6463) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: inherit;
  background-color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 20%) !important;
  border-color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 20%) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: var(--warning-text, #1f6463) !important;
  background-color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 40%) !important;
  border-color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 40%) !important;
}
.btn-danger,
.btn-danger:active {
  background-color: var(--danger-color, #ffea64) !important;
  border-color: var(--danger-color, #ffea64) !important;
  color: var(--danger-text, #645600) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: inherit;
  background-color: color-mix(in srgb,var(--danger-color, #ffea64),#000 20%) !important;
  border-color: color-mix(in srgb,var(--danger-color, #ffea64),#000 20%) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: var(--danger-text, #645600) !important;
  background-color: color-mix(in srgb,var(--danger-color, #ffea64),#000 40%) !important;
  border-color: color-mix(in srgb,var(--danger-color, #ffea64),#000 40%) !important;
}
.btn-white,
.btn-white:active {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
  color: #7a7a7a !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #4f4f4f !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #7a7a7a !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
}
.btn-black,
.btn-black:active {
  background-color: #232323 !important;
  border-color: #232323 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.btn-primary-outline,
.btn-primary-outline:active {
  background-color: transparent !important;
  border-color: var(--primary-color, #9fe870);
  color: var(--primary-color, #9fe870);
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: color-mix(in srgb,var(--primary-color, #9fe870),#000 20%) !important;
  background-color: transparent!important;
  border-color: color-mix(in srgb,var(--primary-color, #9fe870),#000 20%) !important;
  box-shadow: none!important;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: var(--primary-text, #264d0c) !important;
  background-color: color-mix(in srgb,var(--primary-color, #9fe870),#000 40%) !important;
  border-color: color-mix(in srgb,var(--primary-color, #9fe870),#000 40%) !important;
}
.btn-secondary-outline,
.btn-secondary-outline:active {
  background-color: transparent !important;
  border-color: var(--secondary-color, #ffd7ef);
  color: var(--secondary-color, #ffd7ef);
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 20%) !important;
  background-color: transparent!important;
  border-color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 20%) !important;
  box-shadow: none!important;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: var(--secondary-text, #d70081) !important;
  background-color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 40%) !important;
  border-color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 40%) !important;
}
.btn-info-outline,
.btn-info-outline:active {
  background-color: transparent !important;
  border-color: var(--info-color, #320707);
  color: var(--info-color, #320707);
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: color-mix(in srgb,var(--info-color, #320707),#000 20%) !important;
  background-color: transparent!important;
  border-color: color-mix(in srgb,var(--info-color, #320707),#000 20%) !important;
  box-shadow: none!important;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: var(--info-text, #ffffff) !important;
  background-color: color-mix(in srgb,var(--info-color, #320707),#000 40%) !important;
  border-color: color-mix(in srgb,var(--info-color, #320707),#000 40%) !important;
}
.btn-success-outline,
.btn-success-outline:active {
  background-color: transparent !important;
  border-color: var(--success-color, #3a341c);
  color: var(--success-color, #3a341c);
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: color-mix(in srgb,var(--success-color, #3a341c),#000 20%) !important;
  background-color: transparent!important;
  border-color: color-mix(in srgb,var(--success-color, #3a341c),#000 20%) !important;
  box-shadow: none!important;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: var(--success-text, #ffffff) !important;
  background-color: color-mix(in srgb,var(--success-color, #3a341c),#000 40%) !important;
  border-color: color-mix(in srgb,var(--success-color, #3a341c),#000 40%) !important;
}
.btn-warning-outline,
.btn-warning-outline:active {
  background-color: transparent !important;
  border-color: var(--warning-color, #a0e2e1);
  color: var(--warning-color, #a0e2e1);
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 20%) !important;
  background-color: transparent!important;
  border-color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 20%) !important;
  box-shadow: none!important;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: var(--warning-text, #1f6463) !important;
  background-color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 40%) !important;
  border-color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 40%) !important;
}
.btn-danger-outline,
.btn-danger-outline:active {
  background-color: transparent !important;
  border-color: var(--danger-color, #ffea64);
  color: var(--danger-color, #ffea64);
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: color-mix(in srgb,var(--danger-color, #ffea64),#000 20%) !important;
  background-color: transparent!important;
  border-color: color-mix(in srgb,var(--danger-color, #ffea64),#000 20%) !important;
  box-shadow: none!important;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: var(--danger-text, #645600) !important;
  background-color: color-mix(in srgb,var(--danger-color, #ffea64),#000 40%) !important;
  border-color: color-mix(in srgb,var(--danger-color, #ffea64),#000 40%) !important;
}
.btn-black-outline,
.btn-black-outline:active {
  background-color: transparent !important;
  border-color: #232323;
  color: #232323;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #000000 !important;
  background-color: transparent!important;
  border-color: #000000 !important;
  box-shadow: none!important;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #232323 !important;
  border-color: #232323 !important;
}
.btn-white-outline,
.btn-white-outline:active {
  background-color: transparent !important;
  border-color: #fafafa;
  color: #fafafa;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus,
.btn-white-outline.active {
  color: #cfcfcf !important;
  background-color: transparent!important;
  border-color: #cfcfcf !important;
  box-shadow: none!important;
}
.btn-white-outline.disabled,
.btn-white-outline:disabled {
  color: #7a7a7a !important;
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.text-primary {
  color: var(--primary-text, #264d0c) !important;
}
.text-secondary {
  color: var(--secondary-text, #d70081) !important;
}
.text-success {
  color: var(--success-text, #ffffff) !important;
}
.text-info {
  color: var(--info-text, #ffffff) !important;
}
.text-warning {
  color: var(--warning-text, #1f6463) !important;
}
.text-danger {
  color: var(--danger-text, #645600) !important;
}
.text-white {
  color: #fafafa !important;
}
.text-black {
  color: #232323 !important;
}
a.text-primary:hover,
a.text-primary:focus,
a.text-primary.active {
  color: color-mix(in srgb,var(--primary-color, #9fe870),#000 20%);
}
a.text-secondary:hover,
a.text-secondary:focus,
a.text-secondary.active {
  color: color-mix(in srgb,var(--secondary-color, #ffd7ef),#000 20%);
}
a.text-success:hover,
a.text-success:focus,
a.text-success.active {
  color: color-mix(in srgb,var(--success-color, #3a341c),#000 20%);
}
a.text-info:hover,
a.text-info:focus,
a.text-info.active {
  color: color-mix(in srgb,var(--info-color, #320707),#000 20%);
}
a.text-warning:hover,
a.text-warning:focus,
a.text-warning.active {
  color: color-mix(in srgb,var(--warning-color, #a0e2e1),#000 20%);
}
a.text-danger:hover,
a.text-danger:focus,
a.text-danger.active {
  color: color-mix(in srgb,var(--danger-color, #ffea64),#000 20%);
}
a.text-white:hover,
a.text-white:focus,
a.text-white.active {
  color: color-mix(in srgb,#fafafa,#000 20%);
}
a.text-black:hover,
a.text-black:focus,
a.text-black.active {
  color: color-mix(in srgb,#232323,#000 20%);
}
body[data-underlined-links="true"] a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption){
  transition: 0.2s;
  position: relative;
  background-image: linear-gradient(currentColor 50%, currentColor 50%);
  background-size: 10000px 2px;
  background-repeat: no-repeat;
  background-position: 0 1.2em;
}
body[data-underlined-links="false"] a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption){
  position: relative;
  background-image: transparent;
  background-size: 10000px 2px;
  background-repeat: no-repeat;
  background-position: 0px 1.2em;
  background-position: -10000px 1.2em;
}
body[data-underlined-links="false"] a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover{
  transition: background-position 2s ease-in-out;
  background-image: linear-gradient(currentColor 50%, currentColor 50%);
  background-position: 0px 1.2em;
}
body[data-is-rounded-buttons="true"] .nav-tabs .nav-link {
  border-radius: 100px !important;
}
.nav-tabs .nav-link.active {
  color: var(--primary-color, #9fe870);
}
.nav-tabs .nav-link:not(.active) {
  color: #232323;
}
.alert-success {
  background-color: var(--success-color, #70c770);
}
.alert-info {
  background-color: var(--info-color, #320707);
}
.alert-warning {
  background-color: var(--warning-color, #a0e2e1);
}
.alert-danger {
  background-color: var(--danger-color, #ffea64);
}

:root[data-is-rounded-buttons="true"] .mbr-section-btn a.btn:not(.btn-form) {
  border-radius: 100px;
}

:root[data-is-rounded-buttons="true"] .mbr-gallery-filter li a {
    border-radius: 100px !important;
}

.mbr-section-btn .btn:not(.btn-form) {
  border-radius: 100px;
}
.mbr-gallery-filter li a {
  border-radius: 100px !important;
}

.mbr-gallery-filter li.active .btn {
  background-color: var(--primary-color, #9fe870);
  border-color: var(--primary-color, #9fe870);
  color: #ffffff;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}

a,
a:hover {
  color: var(--primary-color, #9fe870);
}

:root[data-is-scroll-to-top-buttons="true"][data-is-rounded-buttons="true"] #scrollToTop a{
  border-radius: 100px;
}
:root[data-is-scroll-to-top-buttons="false"] .scrollToTop_wraper{
  display: none;
}

blockquote {
  border-color: var(--primary-color, #9fe870);
}
/* Forms */
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: var(--primary-color, #9fe870);
  color: #ffffff;
}
.jq-number__spin {
  transition: 0.25s ease;
}
.jq-number__spin:hover {
  border-color: var(--primary-color, #9fe870);
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: #353535;
  border-bottom-color: #353535;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: var(--primary-color, #9fe870);
  border-bottom-color: var(--primary-color, #9fe870);
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #ffffff !important;
  background-color: var(--primary-color, #9fe870) !important;
  box-shadow: none !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000000 !important;
  background: var(--secondary-color, #ffd7ef) !important;
  box-shadow: none !important;
}
.lazy-bg {
  background-image: none !important;
}
.lazy-placeholder:not(section),
.lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
}
iframe.lazy-placeholder,
.lazy-placeholder:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%236592e6' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
section.lazy-placeholder:after {
  opacity: 0.5;
}
body {
  overflow-x: hidden;
}
a {
  transition: color 0.6s;
}
.mbr-fullscreen{
  overflow: hidden;
}

#moultdb-body .CookieConsent {
  z-index: 1040 !important;
  background-color: rgba(0, 0, 0, 0.8);
}

div.dt-buttons {
  margin-top: 0.25em;
}

a[target*="_blank"] {
}
a[target*="_blank"]::after {
  content: "";
  width: 11px;
  height: 11px;
  margin-left: 4px;
  margin-right: 4px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

#moultdb-body main {
  margin-top: 120px;
  margin-bottom: 50px;
}

/*#moultdb-content {*/
/*  font-size: 0.9em;*/
/*  margin-bottom: 40px;*/
/*  margin-top: 40px;*/
/*}*/

#moultdb-body main h1, #moultdb-body main h2, #moultdb-body main h3 {
  font-weight: bold;
}
#moultdb-body main h1 {
  color: var(--primary-color);
  font-size: 1.5em;
  margin-bottom: 20px;
  padding-top: 10px;
  text-align: center;
}
#moultdb-body main h2 {
  border-bottom: 2px var(--primary-color) solid; /* Set color for browsers that do not support gradients */
  border-image: linear-gradient(to right, var(--primary-color) 70%, rgba(146, 2, 9, 0)) 1;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  font-size: 1.2em;
  margin: 1em 0 1em 0;
  width: 100%;
}
#moultdb-body main h3 {
  font-size: 1.1em;
  margin: 2em 0 1em 0;
}
#moultdb-body main h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 2em 0 1em 0;
}
#moultdb-body main h5 {
  font-size: 1em;
  margin: 1em 0 1em 0;
}
/*#moultdb-body a {*/
/*  color: var(--main-medium-color);*/
/*}*/

#moultdb-body .CookieConsent {
  z-index: 1040 !important;
  background-color: rgba(0, 0, 0, 0.8);
}

/*div.dt-buttons {*/
/*  margin-top: 0.25em;*/
/*}*/

/*a[target*="_blank"] {*/
/*}*/
/*a[target*="_blank"]::after {*/
/*  content: "";*/
/*  width: 11px;*/
/*  height: 11px;*/
/*  margin-left: 4px;*/
/*  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");*/
/*  background-position: center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: contain;*/
/*  display: inline-block;*/
/*}*/
.key-value-pair {
  display: flex;
  padding: 0.75rem;
}

.key {
  font-weight: bold;
  width: 25%;
}
 
.value {
  width: 75%;
}

.simple-table {
  table-layout: fixed;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow-x: auto;
  display: block;
  background-color: white;
  width: 100%;
}

.simple-table thead th {
  background-color: white;
}

.simple-table tbody td {
  min-width: 125px;
}

.simple-table th, .simple-table td {
  border: 1px solid #ddd;
  padding: 6px;
  white-space: nowrap;
  word-wrap: break-word;
}

.simple-table tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.simple-table tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.open-row::after, .close-row::after {
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  content: "►";
}

.close-row::after {
  transform: rotate(90deg);
}

/* Beta ribbon */
.beta:after {
  z-index: 10000;
  content: "beta";
  position: fixed;
  width: 100px;
  height: 25px;
  background: darkred;
  top: 12px;
  left: -23px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 26px;
  transform:rotate(-45deg);
}
