/* Group_leaders ##################################################################################################################### */

  .team-section {
    padding-top: 5rem;
    padding-bottom: 3rem;
    background-color: #ffffff;
  }
  .team-section .item-subtitle {
    line-height: 1.2;
    color: #000000;
    text-align: center;
  }
  .team-section img,
  .team-section .item-img {
    max-height: 50vh;
    width: auto;
    height: auto;
    margin: 0 auto;
  }
  .team-section .item:focus,
  .team-section span:focus {
    outline: none;
  }
  .team-section .item {
    margin-bottom: 2rem;
  }
  @media (max-width: 767px) {
    .team-section .item {
      margin-bottom: 1rem;
    }
  }
  .team-section .item-wrapper {
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
  }
  .team-section .mbr-section-btn {
    margin-top: auto !important;
  }
  .team-section .mbr-section-title {
    color: #000000;
  }
  .team-section .mbr-text,
  .team-section .mbr-section-btn {
    color: #000000;
    text-align: center;
  }
  .team-section .item-title {
    color: #000000;
  }
  .team-section .content-head {
    max-width: 800px;
  }