@charset "UTF-8";

@font-face {
  font-family: 'Socicon';
  src:  url('../fonts/socicon.eot');
  src:  url('../fonts/socicon.eot?#iefix') format('embedded-opentype'),
    url('../fonts/socicon.woff2') format('woff2'),
    url('../fonts/socicon.ttf') format('truetype'),
    url('../fonts/socicon.woff') format('woff'),
    url('../fonts/socicon.svg#socicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[data-icon]:before {
  font-family: "socicon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="socicon-"], [class*=" socicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Socicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.socicon-mastodon:before {
  content: "\e913";
}
.socicon-facebook:before {
  content: "\e028";
}
.socicon-github:before {
  content: "\e032";
}
.socicon-instagram:before {
  content: "\e044";
}
.socicon-twitter:before {
  content: "\e08d";
}
